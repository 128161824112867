import * as React from 'react'

import '../components/variables.css'
import '../components/global.css'
import * as styles from './not-found.module.css'
import { PageProps, graphql } from 'gatsby'
import { ContentfulSectionText } from 'types/contentful'
import RichText from 'components/rich-text'
import { GatsbyImage } from 'gatsby-plugin-image'

type DataProps = {
  allContentfulPage404: {
    nodes: [
      {
        sectionText: ContentfulSectionText
      }
    ]
  }
}

const NotFoundPage: React.FC<PageProps<DataProps>> = (props) => {
  // There should always be one published 404 page.
  const { sectionText } = props.data.allContentfulPage404.nodes[0]
  const { buttonText, buttonUrl, heading, image, text } = sectionText
  return (
    <div className={styles.container}>
      {image && (
        <GatsbyImage
          alt={image.title || 'Image'}
          image={image.gatsbyImageData}
          style={{ width: '60%', height: '60%' }}
        />
      )}
      <h1>{heading}</h1>
      {text && <RichText data={text} />}
      <a href={buttonUrl} className={styles.button}>
        {buttonText}
      </a>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentfulPage404(limit: 1) {
      nodes {
        sectionText {
          heading
          buttonUrl
          buttonText
          image {
            gatsbyImageData
            title
          }
          text {
            raw
          }
        }
      }
    }
  }
`
